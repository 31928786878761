import { React, useState } from "react";
import Navbar from "../Components/Navbar";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import ClientTable from "../Components/ClientTable";
import NoBookings from "../Images/no-notifications-hfkUZp4KLR.png";
import axios from "axios";
import { handleApiError } from "../Components/apiErrorHandling";
import { useEstateCode } from "../Components/EstateCodeContext";


const getApiUrl = (path) => {
    const hostname = window.location.hostname;
    if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
      return `https://server.testgrouple.com${path}`;
    }
    if (hostname.includes("grouple.tech")) {
      return `https://production.grouple.tech${path}`;
    }
    throw new Error("Unknown hostname");
  };

const Clients = () => {
  const [selectedSection, setSelectedSection] = useState("All Contacts");
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(true);
  const [allbookings, setAllBookings] = useState([]);
  const { estateCode } = useEstateCode();
  const [tabs, setTabs] = useState([
    { name: "All Contacts", current: true, value: 0 },
  ]);



 
  const handleNavItemClick = (itemName) => {
    setSelectedSection(itemName);

    const updatedTabs = tabs.map((item) => ({
      ...item, 
      current: item.name === itemName,
    }));
    setTabs(updatedTabs);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const fetchBookings = async () => {
    try {
      let choose;
      if (selectedSection === "All Contacts") choose = 0;
     

      const url = getApiUrl(
        `https://server.testgrouple.com/api/v2/merchant/customer/getCustomerData`
      );
      const response = await axios.get(url, {
        withCredentials: true,
      });

      switch (response.status) {
        case 200:
          setAllBookings(response.data.data.reverse());
          break;

        case 204:
          console.warn("No content available");
          setAllBookings([]);
          break;

        default:
          console.warn(`Unexpected status code: ${response.status}`);
          break;
      }
    } catch (error) {
      handleApiError(error);
    }
  };
  

  return (
    <>
      <div>
        <Navbar />

        {/* {/ Add reservation button  /} */}
        <div class="w-full pl-5 pr-5 pt-4 pb-2 items-center justify-between inline-flex">
          <div class="text-neutral-800 text-2xl font-medium font-['Inter']">
            Clients
          </div>

          <div className="flex gap-4">
            <div
              class="bg-white rounded-md cursor-pointer"
              // onClick={handleAddResClick}
            >
              <div class="p-3 rounded-md border border-groupleBlue flex">
                <div class="text-black text-sm font-medium font-['Inter']">
                  Export CSV
                </div>
              </div>
            </div>
            <div
              class="bg-groupleBlue rounded-md cursor-pointer"
              // onClick={handleAddResClick}
            >
              <div class="p-3 rounded-md border border-groupleBlue flex">
                <div class="text-white text-sm font-medium font-['Inter']">
                  New Client
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* tab bar  */}
        <nav className="px-5 flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              onClick={() => handleNavItemClick(tab.name)}
              className={classNames(
                tab.current
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                "flex whitespace-nowrap border-b-2 py-3 px-1 text-sm font-medium"
              )}
              aria-current={tab.current ? "page" : undefined}
              style={{ alignItems: "center" }}
            >
              {tab.name}
            </a>
          ))}
        </nav>

        {/* search bar  */}
        <div className="bg-lightBlue py-6 px-5 flex justify-between">
          <div className="flex gap-2 w-3/4">
            <div style={{ width: "40%" }}>
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative text-gray-400 focus-within:text-gray-600">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search"
                  className="block w-full rounded-md border border-gray-300 py-2.5 pl-10 pr-3 text-gray-900 sm:text-sm sm:leading-6 focus:border-none focus:color-white"
                  placeholder="Search by name, or email id"
                  type="search"
                  name="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* booking table */}

        <div className="bg-lightBlue px-5 pb-10" style={{ minHeight: "75vh" }}>
        
            <ClientTable
              headers={[
                "Customer Name",
                "Phone Number",
                "Email",
                "Visits",
                "Spend",
                "Average Spend",
              
              ]}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setOpen={setOpen}
              open={open}
              fetchBookings={fetchBookings}
             
            />
      
        </div>
      </div>
    </>
  );
};

export default Clients;
